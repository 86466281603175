import React from "react"
import Login from '../components/LoginHelper'
import Layout from "../components/Layout"
import Loginstyles from '../styles/LoginStyles';
import { Container } from "reactstrap";
import { graphql } from 'gatsby';


const LoginPage = ({ location }) => {

  const { state: routeState } = location

  const redirect = (!routeState || routeState.redirect == null )
    ? '/'
    : routeState.redirect === 'pricing'
    ? '/pricing'
    : routeState.redirect === 'app'
      ? '/app'
      : `/app/${routeState.redirect}`

  return (
    <Layout>
      <Loginstyles>
        <div className="login-screen">
          <Container style={{textAlign: "center"}}>
            <br />
      <div>
        <Login redirect={redirect} />
      </div>
      <br />
      </Container>
      </div>
      </Loginstyles>
    </Layout>
  )
}

export default LoginPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["logon", "common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;