import styled from 'styled-components';
const LoaderStyles = styled.div`
body{
    background-color: #f9fafd;
  }
  
  .loader-div{
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    background-color: #f9fafd;
    z-index: 1999;
  }
  .loader-img{
    position: absolute;
    left: 0;
    margin: auto;
    right: 0;
    bottom: 0;
    top: 0;
    width: 200px;
    height: 200px;
  }  

  .siteStyl {
    h1 {
      font-size: 40px;
    }
  
    h2 {
      font-size: 28px;
    }
  
    h3 {
      font-size: 24px;
    }
  
    h4, h5, h6 {
      font-size: 19px;
    }
  
  
    a {
      text-decoration: none;
      color: #FF5B39;
    }
  
    a:hover {
      text-decoration: none;
      color: #FF5B39;
    }
  
    h1, h2, h3, h4, h5, h6 {
      font-family: "Bebas Neue";
    }
  
    p, a {
      font-family: "Work Sans";
    }
  
  }
  
`;

export default LoaderStyles;