import {Component} from 'react'
import React from 'react';
import LoaderImg from '../assets/images/squareGif.gif'
import LoaderStyles from '../styles/LoaderStyles';

class Loader extends Component {
  render()
  {
    return(
      <LoaderStyles>
        <div className="loader-div siteStyl">
            <img className='loader-img' src={LoaderImg} alt="Loader Gif"/>
        </div>
      </LoaderStyles>
    )
  }
}

export default Loader;
