import React, { useState } from 'react'
import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import useAuth from '../hooks/useAuth'
import { fetchUrl } from '../apiClient/baseApi';
import { Container, Row, Col } from 'reactstrap';
import ReactModal from 'react-modal';
import Loader from './Loader';
const toastr = require('toastr')

const Login = ({ redirect }) => {
  const { navigate, t } = useI18next();
  const apiURL = process.env.GATSBY_API_URL
  const { login, social_login, isAuthenticated } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [load, setLoad] = React.useState(false)

  React.useEffect(() => {
    if (typeof window !== `undefined` && !isAuthenticated) {
      const queryString = window.location.search;
      const refToken = new URLSearchParams(queryString).get('reftoken');
      const social = new URLSearchParams(queryString).get('social');
      if (refToken && refToken !== 'false') {
        social_login(refToken).then(() => {
          fetchUrl('get', '/users/me?fields=*.*').then((res) => {
            window.localStorage.setItem('user', JSON.stringify(res.data));
            navigate(redirect)
          })
        })
      }
      else if (social) {
        toastr.error('An account already exists with this email id.')
        toastr.info('Try logging in with email id and password')
      }
    }
  }, [redirect, social_login, isAuthenticated]);

  const handleSubmit = async (event) => {
    event.preventDefault()
    setLoad(true)

    try {
      await login({ email, password })
      await fetchUrl('get', '/users/me?fields=*.*').then((res) => {
        window.localStorage.setItem('user', JSON.stringify(res.data));
      })
      navigate(redirect)
    } catch (e) {
      console.log("Error occurred during authentication")
    }

    setLoad(false)

  }

  const handleSocialSubmit = async (provider) => {
    try {
      await social_login(provider)
      await fetchUrl('get', '/users/me?fields=*.*').then((res) => {
        window.localStorage.setItem('user', JSON.stringify(res.data));
      })
      navigate(redirect)
    } catch (e) {
      console.log("Error occurred during authentication")
    }
  }

  const LinkStyle = {
    textDecoration: "none",
    color: "white",
  }

  const googleLink = apiURL + "/auth/login/google?redirect=" + apiURL + "/redirect-with-token"
  const fbLink = apiURL + "/auth/login/facebook?redirect=" + apiURL + "/redirect-with-token"

  return (
    <div className="bg-white siteStyl" style={{ width: "40%" }}>
      <ReactModal
        isOpen={load}
        contentLabel="Content Loading"
        onRequestClose={() => { setLoad(false) }}
        className="react-confirm-alert-body"
        overlayClassName="react-confirm-alert-overlay"
      >
        <Loader />
      </ReactModal>
      <form
        onSubmit={handleSubmit}
      >
        <br />
        <h2>{t('logon14')}</h2>
        <Row>
          <Col md="12">
            <div style={{ padding: "15px" }}>
              <input
                onChange={e => {
                  setEmail(e.target.value)
                }}
                value={email}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" type="email" required placeholder={t('logon22')} />
            </div>
            <div style={{ padding: "15px" }}>
              <input
                onChange={e => {
                  setPassword(e.target.value)
                }}
                value={password}
                className="shadow appearance-none border border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="password" type="password" required placeholder={t('logon11')} />
            </div>
            <div style={{ padding: "5px", textAlign: "right" }}>
              <Link to="/request-password-reset">{t('logon21')}</Link>
            </div>
            <div className="flex items-center justify-between" style={{ padding: "10px" }}>
              <button className="ma-btn" type="submit">
                {t('logon28')}
              </button>
            </div>
          </Col>
        </Row>
      </form>
      <div className='or' />
      <Container>
        <a href={googleLink} rel="noopener noreferrer" style={LinkStyle}>
          <div className="linkStyle1 google-btn">{t('logon16')}</div></a>
        <p></p>
        <a href={fbLink} rel="noopener noreferrer" style={LinkStyle}>
          <div className="linkStyle1 fb-btn">{t('logon15')}</div></a>
      </Container>
      <br />
      <p> {t('logon19')} <Link to="/register">{t('logon20')}</Link> </p>
      <br />
    </div>
  )
}

export default Login